.download-container{
    margin: 0 auto;
    max-width: 950px;
}

.download-table-selection{
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    color: #f04e38;
    border: #02688E thin solid;
    cursor: pointer;
    appearance: none;
}
.button-check{
    height: 50px;
}
.button-download{
    height: 40px;
}
.download-table-selection:checked{
    background-color: #02688E;
}
.download-table td{
    padding: 3px;

}
.download-table .button-cell{
    display: flex;
    justify-content: center;
    align-items: center;
}


.download-link{
    display: flex;
    width: fit-content;
    gap: 1%;

}
.download-link .form-input{
    width: 93%;
}
.download-link button{
    padding: 0.375rem 0.75rem;
    height: 40px;
}