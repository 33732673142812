.dropdown-layout {
    padding: 6px 0;
}
.dropdown-label-container{
    text-align: center;
}
.dropdown-input {
    cursor: pointer;
    min-height: 40px;
}
.dropdown-input-selected{
    height: inherit;
    overflow: auto;
    /*display: inline-flex;*/
    display: flex;
    align-items: center;
}
.dropdown-input-selected::-webkit-scrollbar { /*WebKit*/
    width: 0;
    height: 0;
}
.dropdown-input-placeholder{
    border: var(--boundary);
    display: flex;
    align-items: center;
    text-align: center;
    color: grey;
    font-style: italic;
}
.dropdown-search-container {
    position: absolute;
    background-color: #ebeced;
    border-radius: var(--borderRadius);
    padding: 5px;
    margin-top: 6px;
}
.search-container {
    padding-bottom: 10px;
}
.filter-item{
    color: var(--secondary-100);
    display: inline-flex;
    margin-right: 10px;
    cursor: pointer;
}
.filter-item:hover{
    text-decoration-line: underline;
}
.dropdown-container {
    overflow-y: auto;
    height: 200px;
}
.select-field {
    border-radius: var(--borderRadius);
    cursor: pointer;

}
.option-field {
    border-bottom: var(--grey-50) thin solid;
}
.option-box {
    margin-right: 4px;
}
.option-box:hover{
    background-color: lightblue;
}
.button-container-dropdown{
    justify-content: flex-end;

}
.dropdown-button{
    box-shadow: none;
}
.upload-button-container{
    padding: 24px 0 0;
    display: flex;
    align-items: center;
}