.columnLayout {
    display: flex;
    gap: 20%;
}

.navButton {
    position: relative;
    left: 150px;
}

.messageLayout {
    display: flex;
    justify-content: center;
    font-size: 1.2em;
}

.delimiterLayout {
    /*border: #f04e38 thin dotted;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentLayout{
    border: #939597 thin solid;
    border-radius: var(--borderRadius);
    margin: 12px 0;
    height: 200px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: "overlap";
}
.uploadIcon{
    grid-area: overlap;
}
.uploadLabel{
    padding: 12px;
    z-index: -1;
}
.contentLayout p{
    padding: 0.43em;
    margin: 0;
}
.contentLayout.dragged{
    background-color: #ebeced;
}
.delimiterLayout > svg {
    stroke: #151b1d;
    stroke-width: 2;
    width: 100px;
    height: 5px;
}

.fileContainer {
    margin-top: 12px;
    font-size: 1.2em;
}

.fileItem {
    display: flex;
    justify-content: space-between;
    background-color: var(--grey-50);
    border-radius: 7px;
    padding: 0 10px;
}

.removeIcon {
    display: flex;
    align-items: center;
    font-size: 1.2em;
}
.removeIcon:hover{
    color: var(--red-light);
}
/*** File Input ***
* make the file invisible.
* positioned absolute will put the input behind the label
*/

.buttonLayout {
    text-align: center;
}
.buttonLayout{
    position: relative;
    z-index: 3;
}
.buttonLayout > input{
    width: 0;
    position:relative;
    padding: 0;
}
.buttonLayout > label{
    width: 153px;
}

.fileUploadDrag{
    opacity: 0;
    left:0;
    top: 0;
    width: 100%;
    height: 171%;
    grid-area: overlap;
    cursor: pointer;

}
.uploadDataLoader{
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 1130px) {
    .columnLayout {
        display: block;
    }
    .messageLayout{
        display: none;
    }
    .delimiterLayout{
        display: none;
    }
}