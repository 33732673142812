.messageContainer{
    height: 100%;
    width: 500px;
}

.messageTextContainer{
    display: flex;
    align-items: center;
    gap: 3%;
}

.pictogram{
    padding-right: 2px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: xxx-large;
}