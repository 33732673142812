.uploadPageContainer{
    display: flex;
    justify-content: center;
}
.uploadPageContentContainer{
    width: 70%;
}
.buttonStyle {
    width: 153px;
    margin:10px;
}