/******Navigation Links*********/
.navLinks{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
}

.navLink{
    width: 150px;
    text-transform: uppercase;
    color: var(--grey-600);
    text-align: center;
    margin-left: 9px;
}

.navLink:hover {
    transform: scale(1);
    color: var(--white);
    border-bottom: white thin solid;
}
.active{
    color: var(--white);
    border-bottom: white thin solid;
}
.icon{
    padding-right: 3px;
}
@media screen and (max-width: 1130px){
    .navLinks{
        flex-direction: column;
        width: 100%;
    }
    .navLink{
        width: 100%;
        font-size: 1.5em;
        line-height: 2em;
    }
    .navLink:hover{
        border: none;
    }
    .active{
        border: none;
        background-color: rgb(0, 156, 183);
    }
}