.range{
   /* position: absolute;
    margin-top: 230px;*/
    min-width: 200px;
}

.approverItem{
    color: lightgrey;
}
.menu{
    padding: 10px;
    background-color: #02688e;
    box-shadow: var(--shadow-3);
}
.link{
    text-decoration: none;
    color: inherit;
    transition: padding-left 200ms;
    transition-timing-function: ease-out;
}
.link:hover{
    text-decoration: none;
    color: #ebeced;
}

.item {
    cursor: pointer;
    padding-left: 0;
}

.item:hover .link{
    padding-left: .2em;
    transition: padding-left 200ms;
    transition-timing-function: ease-in;
}
.itemLogout>button{
    width: 70%;
}
.itemLogout{
    text-align: center;
}
.icon{
    padding-right: 3px;
}
.links-container{
    border: white thin solid;


}
.delimiter{
    border-top: white thin solid;
    border-bottom: none;
    border-left: none;
    border-right: none;
}
@media screen and (max-width: 1130px){
    .range{
        position: initial;
        padding: 0;
        width: 50%;
        text-align: center;

    }
    .menu{
        border: none;
        box-shadow: none;
        border-radius: 0;
        background-color: lightgray;
    }
    .item:hover .link{
        padding-left: 0;
        transition: none;
    }
}