/******Component Container*********/
.nav-container {
    position: fixed;
    width: 100%;
    height: var(--nav-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    background-color: #02688E;
    color: white;
    font-size: large;
}
/******Logo White*********/
.logo-image {
    width: 20%;
}
.logo{
    min-width: 50px;

}
.link{
    text-transform: none;
    color: white;
}
.link:hover{
    color: white;
}
/********User Container*******/
.user-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    /*width: 20%;*/
    padding-right: 1rem;
}
.header-user-picture{
    cursor: pointer;
    padding: 1rem;
}
.header-user-menu{
    position: absolute;
    margin-top: 1.1rem;
    margin-left: -7.5rem;
    display: none;
}
.header-user-picture:hover{
    background-color: #2177a1;
    .header-user-menu{display: block;}
}
.header-user-picture:active{


}
/*******User Link********/
.profile-link{
    color: white;
}
/*******Menu********/
/*the menu class is calculated by the menu component in the menu css, therefor wildcard selection
^starts with
*contains */
div[class^='Menu']{
    opacity: 0;
}
.user-container:hover div[class^='Menu']{
    opacity: 1;
    cursor: initial;
}
.mobileMenu{
    display: none;
}
.links{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}
/******RESPONSIVE*********/
@media (max-width: 1130px) {
    .nav-container {
        position: relative;
        height: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1%;
    }

    .nav-container-active {
        position: absolute;
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 1%;
        background-color: #02688E;
        color: white;
        z-index: 4;

    }
    .mobileMenu{
        display: flex;
        font-size: 2em;
        justify-content: space-between;
        align-items: center;
        padding-right: 25px;
    }
    .user-container{
        display: none;
    }
    .user-container-active{
        display: flex;
        flex-direction: column;
        background-color: lightgray;
        color: var(--black);
        width: 100%;
        align-items: center;
        border: #f04e38 thin dotted;
        font-size: 1.2em;
        z-index: 4;

    }
    div[class^='Menu']{
        opacity: 1;
        transition: none;
    }
    .user-container:hover div[class^='Menu']{
        transition: none;
    }
    .links-container{
        display: none;
    }
    .links-container-active{
        display: flex;
        width: 100%;
    }
    .logo-container{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .logo{
        max-width: 200px;
    }
}