.verify-list-user{
    padding: 20px;
    font-size: xx-large;
}
.verify-list-title{
   margin-bottom: 0;
    text-transform: none;
}
.verify-list-title-user{
    text-transform: capitalize;
}
.subtitle{
    margin-bottom: 1.38rem;
    max-width: 100%;
    text-align: center;
    color: #02688E;
    border: #00b1d0 thin dotted;
}
.verify-list-item{
    display: flex;
    justify-content: space-between;
    padding: 15px 10px;
    margin-bottom: 10px;
    border-radius: var(--borderRadius);
    background-color: #E4F0F2;
}
.debug-boundary{
    border: none;
}