.header {
  margin-bottom: 1em;
  text-align: left;
  font-size: 2em;
}

.header > * {
  display: block;
}

table.table {
  width: 100%;
  margin-bottom: 0.5em;
  font-size: 1em;
  border-collapse: collapse;
  border-spacing: 0;
  border: 2px solid var(--primary-50);
}
table.table tr {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-align: center;
  border-bottom: 1px dotted #ccc;
}
table.table thead {
  border-bottom: 2px solid var(--primary-50);
}

table.table th {
  font-weight: bold;
  padding: 0.35em;
  font-size: 0.9em;
}
table.table td {
  padding: 0.35em;
  font-size: 0.9em;
}

.buttonConfirmed {
  width: auto;
  cursor: pointer;
  border-radius: 8%;
  background-color: var(--secondary-100);
  color: white;
}
.buttonDownload {
  width: 20%;
  cursor: pointer;
  background-color: white;
  color: black;
  border: none;
}

.buttonCanceled {
  width: auto;
  cursor: pointer;
  border-radius: 8%;
  background-color: var(--red-light);
  color: white;
}

.buttonInfo {
  width: 25%;
  cursor: pointer;
  border: none;
  background: none;
  background-color: none;
  color: black;
  text-decoration: underline;
  text-align: center;
}

.linkView {
  cursor: pointer;
  text-decoration: none;
  color: #21A2B9;
}
.linkView:hover {
  text-decoration: underline;
}

.loader {
  display: flex;
  justify-content: center;
}

.buttonSubmit {
  width: auto;
  cursor: pointer;
  border-radius: 8%;
  background-color: var(--secondary-100);
  color: white;
}

.buttonContainerDelimiter{
  width: 70%;
}
/* media queries */
@media screen and (max-width: 1024px) {
  .container {
    max-width: 600px;
    margin: 0 auto;
  }
  .buttonContainerDelimiter{
    width: 100%;
  }
  table.table {
    width: 100%;
  }

  table.table thead {
    display: none;
  }
  table.table tbody {
    width: 100%;
  }
  table.table tr,
  table.table th,
  table.table td {
    display: block;
    padding: 0;
  }
  table.table tr {
    border-bottom: none;
    margin: 0 0 1em 0;
    padding: 0.5em;
  }

  table.table td {
    padding: 0.5em 0 0.25em 0;
    border-bottom: 1px dotted #ccc;
    text-align: center;
  }
  table.table td[data-title]:before {
    content: attr(data-title);
    font-weight: bold;
    display: inline-block;
    content: attr(data-title);
    float: left;
    margin-right: 0.5em;
    font-size: 0.95em;
  }
}