.loadContainer {
    border-radius: var(--borderRadius);
    height: 30px;
    width: 300px;
    background: linear-gradient(
            60deg,
            var(--secondary-100),
            var(--secondary-100) 35%,
            var(--primary-50) 35%,
            var(--primary-50) 60%,
            var(--secondary-100) 60%,
            var(--secondary-100) 100%
    );
    background-size: 30px 100%;
    animation: shift 1s linear infinite;
}
.loaderBoundary{
    display: flex;
    justify-content: center;
}
@keyframes shift {
    to{background-position: 30px 100%}
}