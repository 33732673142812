@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;700;800;900&family=Space+Mono&display=swap");
@import "bootstrap/scss/bootstrap";

*,
::after,
::before {
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

:root {
    /* colors */
    --primary-50: #02688e;
    --primary-100: #04698f;
    --primary-100o85: rgba(4, 105, 143, 0.85);
    --secondary-100: #00b1d0;
    --secondary-200: #f2692a;
    --secondary-300: #f04e38;

    /* grey */
    --grey-50: #ebeced;
    --grey-600: #c6c8ca;
    --grey-700: #939597;
    --grey-800: #414042;
    --grey-900: #151b1d;

    /* rest of the colors */
    --black: #222;
    --white: #fff;
    --red-light: #f04e38;
    --red-dark: #842029;
    --green-light: #d1e7dd;
    --green-dark: #0f5132;

    /* fonts  */
    --headingFont: "Heebo", Sans-Serif;
    --bodyFont: "Heebo", Sans-Serif;
    --small-text: 0.875rem;
    --extra-small-text: 0.7em;
    /* rest of the vars */
    --backgroundColor: var(--grey-50);
    --textColor: var(--grey-900);
    --borderRadius: 0.25rem;
    --letterSpacing: 1px;
    --transition: 0.3s ease-in-out all;
    --max-width: 1120px;
    --fixed-width: 500px;
    --fluid-width: 90vw;
    --breakpoint-lg: 992px;
    --nav-height: 6rem;

    /* box shadow*/
    --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
    --shadow-4: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);

    /* debug */
    --boundary: thin #f04e38 dotted;
}

body {
    font-family: var(--bodyFont);
    font-weight: 400;
    line-height: 1.75;
    color: var(--textColor);
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(--headingFont);
    font-weight: 400;
    line-height: 1.3;
    text-transform: capitalize;
    letter-spacing: var(--letterSpacing);
}

h1 {
    font-size: 3.052rem;
}

h2 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

small,
.text-small {
    font-size: var(--small-text);
}

a {
    text-decoration: none;
    letter-spacing: var(--letterSpacing);
}

a,
button {
    line-height: 1.15;
}

button:disabled {
    cursor: not-allowed;
}

ul {
    list-style-type: none;
    padding: 0;
}

.img {
    width: 100%;
    display: block;
    object-fit: cover;
}

/* containers */
.container {
    @include make-container();
    padding: 2rem;
}

.dc-container {
    border: #151b1d thin solid;
    border-radius: 15px;
    padding: 3% 5%;
    margin: 0 auto;
    max-width: 950px;
}
.dc-container-items{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.dc-container-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-container {
    display: flex;
    justify-content: space-between;
}

.modal-button-container {
    display: flex;
    justify-content: flex-end;
}

/* buttons */
.dc-btn {
    cursor: pointer;
    color: var(--white);
    background: var(--secondary-100);
    border: transparent;
    border-radius: var(--borderRadius);
    letter-spacing: var(--letterSpacing);
    padding: 0.375rem 0.75rem;
    box-shadow: var(--shadow-2);
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
    min-width: 120px;
}

.dc-btn:hover {
    background: var(--primary-50);
    box-shadow: var(--shadow-3);
}

.dc-btn-hipster {
    color: var(--primary-50);
    background: var(--secondary-100);
}

.dc-btn-hipster:hover {
    color: var(--primary-50);
    background: var(--secondary-100);
}

.dc-btn-submit {
    color: white;
    background-color: var(--primary-100);
}

.dc-btn-block {
    width: 100%;
}

.dc-btn:disabled{
    color: white;
    background-color: var(--primary-100);
}
.dc-btn-hero {
    font-size: 1.25rem;
    padding: 0.5rem 1.25rem;
}

.dc-btn-danger {
    background: var(--red-light);
    color: var(--white);
}

.dc-btn-danger:hover {
    background: var(--red-dark);
    color: var(--white);
}

.dc-btn-skillex {
    padding: 6px 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: var(--borderRadius);
    border: none;
    color: #21A2B9;
    font-size: 0.875rem;
}

.dc-btn-skillex:enabled:hover {
    color: white;
    background-color: #02688E;
    cursor: pointer;
}

.dc-btn-skillex:disabled {
    color: #939597;
    border: #939597 thin solid;
}

.dc-btn-skillex-danger {
    padding: 6px 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: var(--borderRadius);
    border: none;
    color: #d16f73;
    font-size: 0.875rem;
}

.dc-btn-skillex-danger:enabled:hover {
    color: white;
    background-color: #d16f73;
    cursor: pointer;
}

.dc-btn-skillex-danger:disabled {
    color: #939597;
    border: #939597 thin solid;
}

.dc-btn-skillex-simple {
    padding: 6px 15px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: var(--borderRadius);
    border: none;
    color: #6c757d;
    font-size: 0.875rem;
}

.dc-btn-skillex-simple:hover {
    color: #fff;
    background-color: #6c757d;
}

.dc-loader-block {
    display: flex;
    justify-content: center;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid var(--grey-600);
    border-radius: 50%;
    border-top-color: var(--primary-50);
    animation: spinner 2s linear infinite;
}

.loading-center {
    margin: 0 auto;
}

/* title */
.title {
    text-align: center;
}

.title-underline {
    background: var(--primary-50);
    width: 7rem;
    height: 0.25rem;
    margin: -1rem auto 0;
}

.full-page {
    min-height: 100vh;
}

/* text */

.message-text {
    font-weight: bold;
}

/* icons */
.icon {
    vertical-align: text-bottom;
}

.bigIcon {
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
}

/*Debug*/
.debug-boundary {
    border: var(--boundary);
}

@media screen and (max-width: 1130px) {

    .coffee-info {
        text-align: left;
    }

    .coffee-info span {
        display: inline-block;
        margin-right: 0.5rem;
    }

    .title {
        font-size: 2rem;
    }
}