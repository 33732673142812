.verify-title {
    text-align: center;
}

.verify-layout {
    display: flex;
    justify-content: center;
}

.verify-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

input::file-selector-button {
    height: inherit;
    cursor: pointer;
    margin: 0 5px 0 0;
    border: 0;
    background-color: var(--grey-600);
}

input::file-selector-button:hover {
    background-color: #939597;
}

.verify-form-input {
    padding: 0;
}